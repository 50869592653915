import { createRouter, createWebHistory } from "vue-router";
import ClientsRoute from "@/routes/Clients";
import TenantsRoute from "@/routes/Tenants";
import TenantRoute from "@/routes/Tenant";
import OtaUploadRoute from "@/routes/OtaUpload";

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        { path: "/", redirect: "/clients" },

        {
            path: "/clients",
            name: "Clients",
            component: ClientsRoute
        },

        {
            path: "/tenants",
            name: "Tenants",
            component: TenantsRoute
        },

        {
            path: "/tenants/:tenant",
            name: "Tenant",
            component: TenantRoute
        },
        {
            path: "/ota-upload",
            name: "OtaUpload",
            component: OtaUploadRoute
        }
    ],
});

export default router;
