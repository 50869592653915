<template>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1>OTA Upload</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-file-input
            v-model="file"
            label="Select OTA zip file"
            accept=".zip"
            @change="analyzeFilename"
          ></v-file-input>
        </v-col>
      </v-row>
      <v-row v-if="error">
        <v-col cols="12">
          <v-alert type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>
      <v-row v-if="debugInfo">
        <v-col cols="12">
          <v-alert type="info">
            <pre>{{ debugInfo }}</pre>
          </v-alert>
        </v-col>
      </v-row>
      <v-row v-if="file">
        <v-col cols="12" sm="6" v-for="(value, key) in updateData" :key="key">
          <v-text-field
            v-model="updateData[key]"
            :label="key"
            :disabled="key === 'filename' || key === 'size'"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="file">
        <v-col cols="12">
          <v-switch
            v-model="updateData.published"
            label="Published"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn @click="uploadFile" :disabled="!file" color="primary">
            Upload OTA
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'OtaUpload',
    data() {
      return {
        file: null,
        error: null,
        debugInfo: null,
        updateData: {
          published: false,
          channel: 'retail',
          device: '',
          filename: '',
          romtype: '',
          url: '',
          version: '',
          size: 0,
          incrementalTimestamp: '',
          tenant: '',
          datetime: null,
        },
      };
    },
    methods: {
      analyzeFilename() {
        this.error = null;
        this.debugInfo = '';
        this.resetUpdateData();
  
        if (!this.file) {
          this.debugInfo = 'No file selected';
          return;
        }
  
        let filename;
        let fileSize;
  
        if (typeof this.file === 'string') {
          filename = this.file;
          this.debugInfo += `File is a string: ${filename}\n`;
        } else if (this.file instanceof File) {
          filename = this.file.name;
          fileSize = this.file.size;
          this.debugInfo += `File is a File object: ${filename}, size: ${fileSize}\n`;
        } else if (Array.isArray(this.file) && this.file.length > 0 && this.file[0] instanceof File) {
          filename = this.file[0].name;
          fileSize = this.file[0].size;
          this.debugInfo += `File is an array of File objects: ${filename}, size: ${fileSize}\n`;
        } else {
          this.error = 'Invalid file format';
          this.debugInfo += `Unexpected file format: ${JSON.stringify(this.file)}\n`;
          return;
        }
  
        this.debugInfo += `Parsing filename: ${filename}\n`;
        const parts = filename.split('-');
        this.debugInfo += `Split parts: ${JSON.stringify(parts)}\n`;
        
        // More flexible parsing
        this.updateData.filename = filename;
        this.updateData.device = parts[0] || '';
        this.updateData.tenant = parts[1] || '';
        this.updateData.version = parts[2] || '';
        this.updateData.romtype = parts[3] || '';
        
        // Find the incremental timestamp (assuming it's always in the format YYYY.MM.DD.NN)
        const incrementalTimestampIndex = parts.findIndex(part => /^\d{4}\.\d{2}\.\d{2}\.\d{2}$/.test(part));
        if (incrementalTimestampIndex !== -1) {
          this.updateData.incrementalTimestamp = parts[incrementalTimestampIndex];
          this.debugInfo += `Found incremental timestamp: ${this.updateData.incrementalTimestamp}\n`;
        } else {
          this.debugInfo += `Incremental timestamp not found\n`;
        }
        
        // Find the datetime (assuming it's always a 10-digit number)
        const datetimeIndex = parts.findIndex(part => /^\d{10}/.test(part));
        if (datetimeIndex !== -1) {
          const datetimePart = parts[datetimeIndex].split('.')[0];
          this.updateData.datetime = parseInt(datetimePart, 10);
          this.debugInfo += `Found datetime: ${this.updateData.datetime}\n`;
        } else {
          this.debugInfo += `Datetime not found\n`;
        }
        
        this.updateData.size = fileSize || 0;
        
        this.debugInfo += `Parsed data: ${JSON.stringify(this.updateData, null, 2)}\n`;
  
        if (!this.updateData.device || !this.updateData.tenant || !this.updateData.version || !this.updateData.romtype) {
          this.error = 'Some required fields could not be parsed from the filename';
        }
      },
      resetUpdateData() {
        this.updateData = {
          published: false,
          channel: 'retail',
          device: '',
          filename: '',
          romtype: '',
          url: '',
          version: '',
          size: 0,
          incrementalTimestamp: '',
          tenant: '',
          datetime: null,
        };
      },
      async uploadFile() {
        if (!this.file) return;
  
        const formData = new FormData();
        formData.append('file', this.file instanceof File ? this.file : this.file[0]);
        formData.append('updateData', JSON.stringify(this.updateData));
  
        try {
          const response = await axios.post('https://ota.safetelecom.net/api/updates/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          console.log('Upload successful:', response.data);
          // Handle success (e.g., show a success message, reset form)
          this.resetUpdateData();
          this.file = null;
          this.debugInfo = null;
        } catch (error) {
          console.error('Upload failed:', error);
          this.error = 'Upload failed: ' + (error.response?.data?.message || error.message);
        }
      },
    },
  };
  </script>